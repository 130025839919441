.PageNote {
 .FormPopup {
  .popup-head {
   .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100vw - 80px);
   }
  }
  .popup-body {
   padding-bottom: 60px;
   .popup-body-vbox1 {
    width: 100%;
    height: 100%;

    .field-name {
     padding: 14px;
     input {
      font-family: "Changa", sans-serif;
      font-weight: 300;
      font-size: 15px;
      line-height: 40px;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
     }
    }

    .field-body {
     padding: 0 14px 0;

     .ql-container {
      background: #ffffffee;
      height: calc(100vh - 120px);
      font-family: "Changa", sans-serif;
      font-weight: 300;
      font-size: 15;
      overflow: auto;
     }

     .ql-editor {
      // min-height: calc(100vh - 180px);
     }
    }

    .simple-body {
     padding: 0 14px;

     .ql-toolbar {
      background: #eee9;
      border-color: #eee9;
     }
     .ql-container {
      border-color: #eee9;
      background: #3336;
      max-height: calc(100vh - 150px);
      font-family: "Changa", sans-serif;
      font-weight: 300;
      font-size: 15;
      overflow: auto;
      color: #fff;

      a {
       color: #00fb99;
      }
     }

     .ql-editor {
      min-height: calc(100vh - 151px);
     }
    }
   }
  }
  .popup-footer {
   position: fixed;
   right: 16px;
   bottom: 14px;

   @keyframes rotating {
    from {
     -webkit-transform: rotate(0deg);
    }
    to {
     -webkit-transform: rotate(360deg);
    }
   }

   .simple-loading {
    // margin: 0 14px;
    // padding: 12px 36px;
    display: inline-block;
    // background-color: pink;
    color: #fffa;
    position: relative;
    width: 24px;
    height: 24px;

    .loader {
     display: block;
     width: 24px;
     height: 24px;
     position: absolute;
     left: 0;
     top: 7px;
    }
    .loader::before {
     content: "";
     display: block;
     width: 24px;
     height: 24px;
     position: absolute;
     background-position: center center;
     background-repeat: no-repeat;
     background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg fill='%23ffffff' width='24px' height='24px' viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z'/%3E%3C/svg%3E");
     animation: rotating 2s linear infinite;
    }
   }

   .label {
    color: white;
    display: inline-block;
   }
   .label.error-result {
    color: #F33;
   }

   button {
    margin-left: 24px;
    font-size: 15px;
    line-height: 38px;
    padding: 0 24px;
    opacity: 0.8;
    border: 1px #00fb99 solid;
    background: #00fb99;
    color: #000;
    cursor: pointer;
    transition: all $hover-duration linear;
   }

   button:hover {
    opacity: 1;
   }

   button:disabled {
    cursor: not-allowed;
    background: none;
    border: 1px #fff solid;
    color: #fff;
    opacity: 0.4;
   }
  }
  // position: fixed;
 }

 .nodes-tree {
  .nodes-sublevel {
   padding-left: 24px;
  }
  .node {
   margin: 8px 0;
   // .node-name {
   //  span {
   //   cursor: pointer;
   //   color: #00fb99;
   //  }
   // }
   // .node-expand-box {
   //  padding-left: 24px;

   //  .content-done {
   //   border: 1px #eee solid;
   //   padding: 12px;
   //   p {
   //    padding: 2px 0;
   //   }
   //  }
   // }
   .node-simple {
    display: flex;
    .node-simple-name {
     a {
      color: #ffffff;
      // cursor: pointer;
      line-height: 24px;
      text-shadow: #0006 0 0 3px;
      text-decoration: none;
     }
     a.with-content {
      color: #00fb99;
     }
    }
    .node-simple-controls {
     margin-left: 14px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
     position: relative;
     min-width: 18px;

     .trigger {
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      // visibility: hidden;
      // background: yellow;
     }
     .trigger::before {
      display: block;
      // background: pink;
      width: 24px;
      height: 24px;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      background-position: center center;
      background-repeat: no-repeat;
      @extend %svg-shevron;
      transition: all $hover-duration linear;
     }
     .trigger:hover::before {
      background-color: #0009;
     }
     .trigger:checked {
     }
     .trigger:checked::before {
      transform: rotate(180deg);
      // background-color: yellow;
     }
     .actions {
      display: none;
      margin-left: 24px;
      button {
       background: none;
       border: none;
       // margin-left: 10px;
       color: #fff;
       font-size: 14px;
       padding: 0 12px;
       line-height: 24px;
       cursor: pointer;
       transform: all $hover-duration linear;
      }
      button:hover {
       color: #00fb99;
       background-color: #0009;
      }
     }
     .trigger:checked + .actions {
      display: inline-block;
     }
    }
   }
  }
 }
}
