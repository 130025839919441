.AppError {
 margin: 0 auto;
 padding: 144px 32px 32px;
 color: #FFF;
 font-size: 15px;
 font-weight: 300;
 max-width: 360px;

 .title {
  font-weight: 100;
  font-size: 24px;
  line-height: 33px;
  padding: 0;
  margin: 0;
}
 .message {
  padding: 28px 0 0;
  line-height: 28px;
 }
}