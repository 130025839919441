.Sidebar {
 position: relative;
 .logo {
  position: fixed;
  z-index: 30;
  left: 24px;
  top: 84px;

  a {
   @extend %svg-logo;
   display: block;
   width: 13px;
   height: 46px;
   transition: opacity $hover-duration linear;

   span {
    display: none;
   }
  }

  a:hover {
   opacity: 0.5;
  }
 }

 .title {
  position: fixed;
  z-index: 30;
  transform-origin: 0 center;
  transform: rotate(270deg);
  left: 32px;
  top: 330px;
  // top: 50%;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  color: #fff;

  span.alive {
   color: #00fb99;
  }
  a {
   color: #00fb99;
  }

  a {
   text-decoration: none;
  }
 }

 .username {
  position: fixed;
  z-index: 30;
  transform-origin: 0 center;
  transform: rotate(270deg);
  left: 32px;
  top: 530px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 27px;
  opacity: 0.4;
  a {
   color: inherit;
   text-decoration: none;
   transition: all $menu-duration linear;
  }
  a:hover {
   opacity: 0.6;
  }
 }

 .icons {
  z-index: 30;
  position: fixed;
  // left: 50px;
  // top: 50%;
  // margin-left: -12px;
  // margin-top: -78px;
  left: 24px;
  top: 24px;
  margin: 0;

  ul {
   margin: 0;
   padding: 0;

   li {
    list-style: none;
    display: block;
    padding: 0;
    margin: 0;

    .icon {
     display: block;
     width: 16px;
     height: 16px;
     background-color: transparent;
     background-repeat: no-repeat;
     background-position: center center;
     border: none;
     outline: none;
    }

    .icon:hover {
     cursor: pointer;
    }

    .icon-menu {
     @extend %svg-burger;
     // @extend %svg-menu24;
     transition: transform $menu-duration linear;
    }

    .icon-instagram {
     @extend %svg-instagram;
    }

    .icon-mail {
     @extend %svg-mail;
    }

    a {
     span {
      display: none;
     }
    }
   }

   .other-item {
    padding-top: 40px;
    display: none;
   }
  }
 }

 @media (min-width: $tablet-min-width) {
  .logo {
   left: 40px;
   top: 56px;
  }

  .title {
   left: 48px;
   top: calc(50% + (180px - 24px) / 2);
   @media (max-height: $tablet-album-min-height) {
    top: 240px;
    .copyright {
     display: none;
    }
   }
  }

  .username {
   left: 48px;
   top: calc(100vh / 2 - 124px);
   width: calc(100vh / 2 - 80px - 46px - 56px - 32px - 32px);
   text-align: center;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
  }

  .icons {
   left: 100px;
   top: 50%;
   margin-left: -8px;
   margin-top: -64px;

   ul {
    .other-item {
     display: block;
    }
   }
  }
 }

 @media (min-width: $desktop-min-width) {
  .icons {
   left: 200px;
  }
 }
}

.App-menu-shown {
 .Sidebar {
  .icons {
   .icon-menu {
    transform: rotate(90deg);
   }
  }
 }
}
