@keyframes pulse {
 0% {
  transform: scale(0.5);
 }
 50% {
 }
 100% {
  transform: scale(2);
  border-radius: 100%;
 }
}
.AppPulseLoadingIndicator {
 position: fixed;
 z-index: 999;
 top: 4px;
 right: 4px;
 width: 6px;
 height: 6px;
 background: white;
 opacity: 0.1;
 animation-name: pulse;
 animation-duration: 1s;
 animation-timing-function: ease-out;
 animation-direction: alternate;
 animation-iteration-count: infinite;
 animation-play-state: running;
}
.PopupGallery {
 position: fixed;
 z-index: 500;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 background: rgba(0, 0, 0, 0.9);
 // background: rgba(0, 6, 22, 0.8);
 // backdrop-filter: blur(10px);

 .popup-head {
  position: fixed;
  z-index: 502;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  height: 40px;

  .title {
   font-size: 15px;
   line-height: 40px;
   color: #fff;
   opacity: 0.8;
   text-transform: uppercase;
   padding: 0 16px;
  }

  .close {
   button {
    width: 40px;
    height: 40px;
    border: 0;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0.8;
    cursor: pointer;
    @extend %svg-x;
   }
  }
 }

 .popup-body {
  padding: 40px 0;
  box-sizing: border-box;
  height: 100%;

  .popup-body-box {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;
   .video {
    position: relative;
    width: 100%;
    @media (min-aspect-ratio: 16/9) {
     height: 100%;
     background: black;
    }
    @media (max-aspect-ratio: 16/9) {
     padding-top: 56.25%;
     background: black;
    }

    iframe {
     position: absolute;
     z-index: 501;
     left: 0;
     top: 0;
     @media (min-aspect-ratio: 16/9) {
      width: 100%;
      height: 100%;
     }
     @media (max-aspect-ratio: 16/9) {
      width: 100%;
      height: 100%;
     }
    }
   }
  }
  .popup-body-vbox {
   display: flex;
   width: 100%;
   height: 100%;
   justify-content: center;
   align-items: center;

   .image-gallery {
    width: 100vw;
   }
   .embeded-iframe {
    width: 100%;
    height: calc(100vh - 80px);
    border: 0;
   }
   .embeded-frame {
    background: yellowgreen;
    width: 100%;
    height: calc(100vh - 80px);
    iframe {
     width: 100%;
     height: calc(100vh - 80px);
     border: 0;
    }
   }
  }

  button {
   outline: none;
  }

  img {
   outline: none;
  }

  button.gallery-button {
   position: fixed;
   bottom: 0;
   // z-index: 4;
   // top: 100%;
   // margin-top: -20px;
   width: 40px;
   height: 40px;
   border: none;
   // border-radius: 50%;
   // background-color: rgba(0,0,0,.4);
   background-color: transparent;
   background-position: center center;
   background-repeat: no-repeat;
   opacity: 0.8;
   cursor: pointer;
  }
  button.gallery-button:disabled {
   opacity: 0.2;
   cursor: not-allowed;
  }

  button.gallery-button-prev {
   @extend %svg-shevron;
   right: 40px;
   transform: rotate(180deg);
  }

  button.gallery-button-next {
   @extend %svg-shevron;
   right: 0px;
  }

  .image-gallery-bullets {
   position: fixed;
   bottom: 0;
   // top: 100%;
   left: 16px;
   right: 80px;
   // width: 100%;
   margin: 18px 0;

   .image-gallery-bullets-container {
    text-align: left;

    .image-gallery-bullet {
     border: none;
     background: #fff;
     opacity: 0.2;
     transition: all $menu-duration linear;
     box-shadow: none;
     margin: 0 16px 0 0;
     padding: 3px;
    }

    .active {
     opacity: 0.8;
    }
   }
  }
 }

 .popup-auth {
  max-width: 496px;
  margin: 0 auto;
  padding-top: calc(100vh / 2 - 208px / 2);
  .box {
   margin: 0 16px;
   box-sizing: border-box;
   background: rgba(255, 255, 255, 0.4);
   border-radius: 24px;
   .label {
    padding: 24px 32px 66px;
    text-transform: uppercase;
    color: #000616;
   }
   .action {
    padding: 24px 32px;
    text-align: right;
    button.close {
     border: none;
     background: #00fb99;
     white-space: nowrap;
     font-size: 15px;
     line-height: 40px;
     display: inline-block;
     padding: 0 36px;
     color: #000;
     cursor: pointer;
     transition: all $menu-duration linear;
    }
    button.close:hover {
     color: #333;
     // opacity: .8;
    }
   }
  }
 }
}

.App {
 position: relative;

 .layer-background-video {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all $menu-duration linear;

  video {
   position: absolute;
   top: 0;
   transition: all 1600ms cubic-bezier(0.4, 0, 0.4, 1) 0ms;
  }

  @media (min-aspect-ratio: 960/1080) {
   // 720/1280
   video {
    left: 0;
    width: 100%;
    height: auto;
   }
  }

  @media (max-aspect-ratio: 960/1080) {
   video {
    height: 100%;
    width: auto;
    // left: calc((960 / 1080 * 100vh - 100vw) / -2);
    left: calc((960 / 1080 * var(--app-height) - 100vw) / -2);
   }
  }
 }

 $radialSize: 250px;

 .layer-radial {
  position: fixed;
  z-index: 19;
  left: calc(24px - $radialSize);
  top: calc(24px - $radialSize);
  width: $radialSize * 2;
  height: $radialSize * 2;
  background-image: radial-gradient(#000616aa 0%, #00061600 $radialSize);
 }
 .layer-conic {
  position: fixed;
  z-index: 20;
  // left: calc(-100vw - 100vh + 32px);
  // top: calc(-100vw - 100vh + 32px);
  // width: calc(200vw + 200vh);
  // height: calc(200vw + 200vh);
  left: calc(-100vw - var(--app-height) + 32px);
  top: calc(-100vw - var(--app-height) + 32px);
  width: calc(200vw + 2 * var(--app-height));
  height: calc(200vw + 2 * var(--app-height));
  background: conic-gradient(
   from -80deg,
   rgba(255, 255, 255, 0.6),
   rgba(255, 255, 255, 0) 76deg,
   rgba(255, 255, 255, 0.4) 137deg,
   rgba(255, 255, 255, 0.1) 204deg,
   rgba(255, 255, 255, 0.2) 280deg,
   rgba(255, 255, 255, 0.3) 320deg,
   rgba(255, 255, 255, 0.6)
  );
  background-repeat: no-repeat;
  background-size: contain;
  transition: transform $menu-duration linear;
  transform-origin: center center;
 }

 @media (min-width: $tablet-min-width) {
  .layer-radial {
   left: calc(100px - $radialSize);
   top: 50%;
   margin-top: calc($radialSize * -1 - 56px);
  }
  .layer-conic {
   //  left: calc(-100vw - 100vh + 100px);
   //  top: calc(-100vw - 50vh - 56px);
   left: calc(-100vw - var(--app-height) + 100px);
   top: calc(-100vw - var(--app-height) / 2 - 56px);
  }
 }

 @media (min-width: $desktop-min-width) {
  .layer-radial {
   left: calc(200px - $radialSize);
  }
  .layer-conic {
   //  left: calc(-100vw - 100vh + 200px);
   left: calc(-100vw - var(--app-height) + 200px);
  }
 }

 .layer-content {
  // margin-left: 64px;
  // margin-right: 16px;
  // padding: 16px 0;
  margin-right: 16px;
  padding: 16px 0 16px 64px;
  box-sizing: border-box;
  transition: all $menu-duration linear;

  @media (min-width: $tablet-min-width) {
   // margin-left: 164px;
   padding-left: 164px;
   padding-top: 56px;
   padding-bottom: 56px;
  }

  @media (min-width: $desktop-min-width) {
   // margin-left: 224px;
   padding-left: 224px;
  }

  .content-wrapper {
   margin: 0 auto;
   max-width: 800px;
   z-index: 80;
   position: relative;
  }
 }
}

.App-home {
 .layer-background-video {
  video {
   opacity: 1;
   top: 0;
  }
 }
}

.App-page {
 .layer-background-video {
  video {
   opacity: 0.1;

   @media (min-aspect-ratio: 960/1080) {
    // top: calc(100vh - 1080 / 960 * 100vw);
    top: calc(var(--app-height) - 1080 / 960 * 100vw);
   }
  }
 }
}

.App-menu-shown {
 .layer-conic {
  transform: rotate(90deg);
 }
 .layer-background-video {
  video {
   opacity: 0.1;
  }
 }
 .layer-content {
  opacity: 0;
 }
}

.App-last-access {
 display: none;
}

.PopupGallery + .App {
 .layer-background-video {
  filter: blur(10px);
 }
 .layer-content {
  filter: blur(10px);
  // filter: saturate(0.1);
 }
 .blazon {
  opacity: 0;
 }
 .Sidebar {
  display: none;
 }
}
