:root {
  --app-height: 100%;
}

html,
body {
   padding: 0;
   margin: 0;
  //  overflow: hidden;
   width: 100vw;
   height: 100vh;
   height: var(--app-height);
}

html {
 -webkit-text-size-adjust: 100%;
//  width: 100vw;
//  height: 100vh;
//  height: var(--app-height);
//  height: -webkit-fill-available;
}

body {
  background: #000616;
  // margin: 0;
  // padding: 0;
  // min-height: 100vh;
  // min-height: -webkit-fill-available;
  font-family: 'Changa', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  position: relative;
  font-family: 'Changa', sans-serif;
  font-weight: 300;
 }

 * {
  padding: 0;
  margin: 0;
 }

 #root {
   position: relative;
 }
