.PageLogin {
 position: relative;

 .loginFormWrapper {
  padding-top: calc(100vh / 2 - 215px / 2 - 56px);
 }

 .loginFormCard {
  max-width: 464px;
  margin: 0 auto;
  box-sizing: border-box;
  backdrop-filter: blur(10px);

  form {
   padding: 0;
   margin: 0;
   background-color: rgba(255, 255, 255, 0.2);
   border: 1px solid rgba(255, 255, 255, 0.2);
   border-radius: 24px;
  }

  .title {
   color: #000;
   // opacity: .8;
   letter-spacing: 2px;
   line-height: 27px;
   text-transform: uppercase;
   padding: 32px 32px 24px;
  }

  .inputs {
   .input-login {
    background: #000;
    padding: 5px 18px;
    box-sizing: border-box;
   }

   .input-passwd {
    margin-top: 1px;
    background: #000;
    padding: 5px 18px;
    box-sizing: border-box;
   }

   .input-wrapper {
   }

   input {
    text-align: left;
    background: none;
    width: 100%;
    border: none;
    color: #fff;
    caret-color: #fff;
    font-size: 15px;
    line-height: 30px;
    outline: none;
    text-align: center;
   }

   span {
    display: none;
    line-height: 30px;
    color: #fff;
    opacity: 0.6;
   }

   @media (min-width: $tablet-min-width) {
    background: #000;
    padding: 5px 0;
    position: relative;
    text-align: center;

    .input-login {
     background: none;
     position: absolute;
     left: 0;
     top: 5px;
     width: 50%;
     padding: 0 12px 0 32px;
    }

    .input-passwd {
     background: none;
     position: absolute;
     right: 0;
     top: 5px;
     width: 50%;
     padding: 0 32px 0 12px;
     margin-top: 0;
    }

    span {
     display: inline;
    }
   }
  }

  .err {
   margin-top: 1px;
   background: #a00;
   color: #fff;
   text-align: center;
   padding: 5px 16px;
  }

  .buttons {
   padding: 24px 32px;
   text-align: right;

   button {
    box-sizing: border-box;
    line-height: 38px;
    padding: 0 32px;
    border: none;
    opacity: 0.8;
    background: #00fb99;
    color: #000;
    transition: all $hover-duration linear;
    cursor: pointer;
   }
   button:hover {
    opacity: 1;
   }
   button:disabled {
    opacity: 0.2;
    background: none;
    border: 1px solid #000;
    color: #000;
    cursor: not-allowed;
   }
  }
 }
}
